body {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    background-color: rgb(0, 0, 30);
    overflow: hidden;
}

#app {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
}