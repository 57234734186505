#root {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 0;
    pointer-events: none;
}

#content {
    max-width: 700px;
    border: 2px dotted #BADA55;
    pointer-events: all;
    overflow-y: scroll;
    -webkit-mask-image: linear-gradient(transparent 0%, black 10%, black 90%, transparent 100%);
    mask-image: linear-gradient(transparent 0%, black 10%, black 90%, transparent 100%);
}

#top {
    color: white;
    font-family: monospace;
    padding: 20px 8%;
    margin-top: 50px;
    border-bottom: 2px dotted #BADA55;
}

#top h1 {
    font-weight: 200;
    font-size: 16px;
    letter-spacing: 2px;
    margin-bottom: 15px;
}

#top a {
    margin-top: 15px;
    display: block;
}

#top a, .thing a {
    text-decoration: underline;
    color: white;
    cursor: pointer;
}

.thing {
    border-bottom: 1px dotted #BADA55;
    width: 100%;
    padding: 20px 8%;
    font-family: monospace;
    color: white;
    -webkit-mask-image: linear-gradient(90deg, transparent 0%, black 10%, black 90%, transparent 100%);
    mask-image: linear-gradient(90deg, transparent 0%, black 10%, black 90deg, transparent 100%);
}

.thing:last-of-type {
    margin-bottom: 50px;
    border-bottom: none;
}

.thing > p:first-of-type {
    margin-bottom: 15px;
}

.thing p, .thing a {
    margin-top: 10px;
}

.thing a {
    display: block;
}

@media (orientation: landscape) {
    #content {
        width: 80vh;
        height: 80vh;
    }
}

@media (orientation: portrait) {
    #content {
        width: 80vw;
        height: 80vw;
    }
}

@media only screen and (max-width : 600px) {
    #content {
        height: 80vh;
        width: 90vw;
    }

    .thing div a {
        word-wrap: break-word;
    }
}