* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    user-select: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
    /* font-family: sans-serif; */
    /* font-size: 12px;
    font-weight: 100;
    letter-spacing: 1px; */
}

*::-webkit-scrollbar {
    display: none;
}